import { CatalogServiceDto, ServiceType } from '@wix/bookings-uou-types';
import {
  CLASSIC_PRESET_ID,
  GRID_PRESET_ID,
  OVERLAPPING_PRESET_ID,
  STRIP_PRESET_ID,
  BOOKINGS_MAIN_PAGE_PRESET_ID,
  SINGLE_SERVICE_PRESET_ID,
  CLASSIC_EDITOR_X_PRESET_ID,
  OVERLAPPING_EDITOR_X_PRESET_ID,
  STRIP_EDITOR_X_PRESET_ID,
  GRID_EDITOR_X_PRESET_ID,
  BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID,
  SINGLE_SERVICE_EDITOR_X_PRESET_ID,
} from '../../../consts';
import {
  DUMMY_CATEGORY_1,
  DUMMY_CATEGORY_2,
  DUMMY_CATEGORY_3,
} from '../dummyCategoriesDto/dummyCategoriesDto';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

interface Map {
  [key: string]: (t: Function) => CatalogServiceDto[];
}

export const createDummyServicesDto = (
  flowAPI: ControllerFlowAPI,
  presetId?: string,
): CatalogServiceDto[] => {
  const {
    translations: { t },
  } = flowAPI;
  const isWixStudio =
    flowAPI.controllerConfig.wixCodeApi?.location?.query?.dsOrigin === 'studio';

  const dummyMap: Map = {
    [CLASSIC_PRESET_ID]: createClassicDummyServicesDto,
    [OVERLAPPING_PRESET_ID]: createOverlappingDummyServicesDto,
    [STRIP_PRESET_ID]: createStripDummyServicesDto,
    [GRID_PRESET_ID]: createGridDummyServicesDto,
    [BOOKINGS_MAIN_PAGE_PRESET_ID]: createBookingsMainPageDummyServicesDto,
    [SINGLE_SERVICE_PRESET_ID]: createSingleServiceDummyServicesDto,
    [CLASSIC_EDITOR_X_PRESET_ID]: isWixStudio
      ? createWixStudioClassicDummyServicesDto
      : createEditorXClassicDummyServicesDto,
    [OVERLAPPING_EDITOR_X_PRESET_ID]: isWixStudio
      ? createWixStudioOverlappingDummyServicesDto
      : createEditorXOverlappingDummyServicesDto,
    [STRIP_EDITOR_X_PRESET_ID]: isWixStudio
      ? createWixStudioStripDummyServicesDto
      : createEditorXStripDummyServicesDto,
    [GRID_EDITOR_X_PRESET_ID]: isWixStudio
      ? createWixStudioGridDummyServicesDto
      : createEditorXGridDummyServicesDto,
    [BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID]: isWixStudio
      ? createWixStudioBookingsMainPageDummyServicesDto
      : createEditorXBookingsMainPageDummyServicesDto,
    [SINGLE_SERVICE_EDITOR_X_PRESET_ID]: isWixStudio
      ? createWixStudioSingleServiceDummyServicesDto
      : createEditorXSingleServiceDummyServicesDto,
  };

  return dummyMap[presetId as any](t);
};

export const createClassicDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyServices: CatalogServiceDto[] = [];

  const dummyService1 = {
    id: 'classic-service-1',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.GROUP,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.offering-1.classic.name.v1'),
      tagLine: t('dummy.offering-1.classic.tagLine.v1'),
      images: [
        {
          width: 5161,
          height: 3492,
          relativeUri: '11062b_0784fee4aebd4b178e31f63f6c121dd6~mv2.jpg',
          fileName: '11062b_0784fee4aebd4b178e31f63f6c121dd6~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      priceText: t('dummy.offering-1.classic.price.v1'),
      isFree: true,
    },
    schedulePolicy: {},
    scheduleHeader: {
      durationInMinutes: 60,
      days: ['mon', 'tue', 'fri'],
    },
    dummy: true,
  };
  const dummyService2 = {
    id: 'classic-service-2',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.GROUP,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.offering-2.classic.name.v1'),
      tagLine: t('dummy.offering-2.classic.tagLine.v1'),
      images: [
        {
          width: 8688,
          height: 5792,
          relativeUri: '11062b_9fc6d79b5e2b42be9f41346778381dab~mv2.jpg',
          fileName: '11062b_9fc6d79b5e2b42be9f41346778381dab~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 20,
    },
    schedulePolicy: {},
    scheduleHeader: {
      durationInMinutes: 60,
      days: ['mon', 'tue'],
    },
    dummy: true,
  };
  const dummyService3 = {
    id: 'classic-service-3',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.GROUP,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.offering-3.classic.name.v1'),
      tagLine: t('dummy.offering-3.classic.tagLine.v1'),
      images: [
        {
          width: 5256,
          height: 3504,
          relativeUri: '11062b_0446500bceec497899a6adfb2d5cff9e~mv2.jpg',
          fileName: '11062b_0446500bceec497899a6adfb2d5cff9e~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 20,
    },
    schedulePolicy: {},
    scheduleHeader: {
      durationInMinutes: 90,
      days: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
    },
    dummy: true,
  };

  // @ts-expect-error
  dummyServices.push(dummyService1, dummyService2, dummyService3);
  return dummyServices;
};

export const createEditorXClassicDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyServices: CatalogServiceDto[] = [];
  const dummyService1 = {
    id: 'classic-service-1',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.editor-x.offering-1.classic.name'),
      tagLine: t('dummy.editor-x.offering-1.classic.tagLine'),
      images: [
        {
          width: 5630,
          height: 3135,
          relativeUri: '11062b_0f2218fd616445c7ab09ca78aacdb169~mv2.jpg',
          fileName: '11062b_0f2218fd616445c7ab09ca78aacdb169~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 60,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService2 = {
    id: 'classic-service-2',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.GROUP,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.editor-x.offering-2.classic.name'),
      tagLine: t('dummy.editor-x.offering-2.classic.tagLine'),
      images: [
        {
          width: 6939,
          height: 5411,
          relativeUri: '11062b_77255a5b3f0247d6b8a5dc7f02ff601f~mv2.jpg',
          fileName: '11062b_77255a5b3f0247d6b8a5dc7f02ff601f~mv2.jpg',
        },
      ],
    },
    payment: {
      isFree: true,
      priceText: 'Members Only',
    },
    schedulePolicy: {},
    scheduleHeader: {
      durationInMinutes: 40,
      days: ['mon', 'wed'],
    },
    dummy: true,
  };

  // @ts-expect-error
  dummyServices.push(dummyService1, dummyService2);
  return dummyServices;
};

export const createWixStudioClassicDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyServices: CatalogServiceDto[] = [];
  const dummyService1 = {
    id: 'classic-service-1',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.wix-studio.offering-1.classic.name'),
      tagLine: t('dummy.wix-studio.offering-1.classic.tagLine'),
      images: [
        {
          width: 5630,
          height: 3135,
          relativeUri: 'e320d0_074ef222f6994ca1abb62513190b9eb4~mv2.jpg',
          fileName: 'e320d0_074ef222f6994ca1abb62513190b9eb4~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: t('dummy.wix-studio.currency'),
      price: 60,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService2 = {
    id: 'classic-service-2',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.GROUP,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.wix-studio.offering-2.classic.name'),
      tagLine: t('dummy.wix-studio.offering-2.classic.tagLine'),
      images: [
        {
          width: 6939,
          height: 5411,
          relativeUri: 'e320d0_805d10b202464cbea48af64c4c317622~mv2.jpg',
          fileName: 'e320d0_805d10b202464cbea48af64c4c317622~mv2.jpg',
        },
      ],
    },
    payment: {
      isFree: true,
      priceText: t('dummy.wix-studio.offering-2.classic.price'),
    },
    schedulePolicy: {},
    scheduleHeader: {
      durationInMinutes: 40,
      days: ['mon', 'wed'],
    },
    dummy: true,
  };

  const dummyService3 = {
    id: 'classic-service-3',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.GROUP,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.wix-studio.offering-3.classic.name'),
      tagLine: t('dummy.wix-studio.offering-3.classic.tagLine'),
      images: [
        {
          width: 6939,
          height: 5411,
          relativeUri: 'e320d0_c0bad1b383554a82b1d4dfdb500eaa3a~mv2.jpg',
          fileName: 'e320d0_c0bad1b383554a82b1d4dfdb500eaa3a~mv2.jpg',
        },
      ],
    },
    payment: {
      isFree: true,
      priceText: t('dummy.wix-studio.offering-3.classic.price'),
    },
    schedulePolicy: {},
    scheduleHeader: {
      durationInMinutes: 40,
      days: ['mon', 'wed'],
    },
    dummy: true,
  };

  // @ts-expect-error
  dummyServices.push(dummyService1, dummyService2, dummyService3);
  return dummyServices;
};

export const createOverlappingDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyServices: CatalogServiceDto[] = [];

  const dummyService1 = {
    id: 'overlapping-service-1',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.offering-1.overlapping.name.v1'),
      tagLine: t('dummy.offering-1.overlapping.tagLine.v1'),
      images: [
        {
          width: 4827,
          height: 3620,
          relativeUri: '11062b_1edac74605d1465c97cd5de495f4878f~mv2.jpg',
          fileName: '11062b_1edac74605d1465c97cd5de495f4878f~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 50,
    },
    schedulePolicy: {},
    scheduleHeader: {
      durationInMinutes: 45,
      isDurationVaries: false,
      days: ['mon', 'thu'],
    },
    dummy: true,
  };

  const dummyService2 = {
    id: 'overlapping-service-2',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.GROUP,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.offering-2.overlapping.name.v1'),
      tagLine: t('dummy.offering-2.overlapping.tagLine.v1'),
      images: [
        {
          width: 3247,
          height: 2598,
          relativeUri: '11062b_2c7d9a449e2f42d08f4ed5ae827e230c~mv2.jpg',
          fileName: '11062b_2c7d9a449e2f42d08f4ed5ae827e230c~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 70,
    },
    schedulePolicy: {},
    scheduleHeader: {
      durationInMinutes: 90,
      days: ['mon'],
    },
    dummy: true,
  };
  // @ts-expect-error
  dummyServices.push(dummyService1, dummyService2);
  return dummyServices;
};

export const createEditorXOverlappingDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyServices: CatalogServiceDto[] = [];

  const dummyService1 = {
    id: 'overlapping-service-1',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.editor-x.offering-1.overlapping.name'),
      tagLine: t('dummy.editor-x.offering-1.overlapping.tagLine'),
      images: [
        {
          width: 3425,
          height: 4153,
          relativeUri: '11062b_ee4c6f7007fb49b5ab00657a5be9471a~mv2.jpg',
          fileName: '11062b_ee4c6f7007fb49b5ab00657a5be9471a~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 50,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService2 = {
    id: 'overlapping-service-2',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.editor-x.offering-2.overlapping.name'),
      tagLine: t('dummy.editor-x.offering-2.overlapping.tagLine'),
      images: [
        {
          width: 4480,
          height: 3769,
          relativeUri: '11062b_bf0adb87a3c34b77b3444b84abd04515~mv2.jpg',
          fileName: '11062b_bf0adb87a3c34b77b3444b84abd04515~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 75,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService3 = {
    id: 'overlapping-service-3',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.editor-x.offering-3.overlapping.name'),
      tagLine: t('dummy.editor-x.offering-3.overlapping.tagLine'),
      images: [
        {
          width: 5760,
          height: 6720,
          relativeUri: '11062b_85d5afe9a7744a7382481773058866c8~mv2.jpg',
          fileName: '11062b_85d5afe9a7744a7382481773058866c8~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 50,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };

  // @ts-expect-error
  dummyServices.push(dummyService1, dummyService2, dummyService3);
  return dummyServices;
};

export const createWixStudioOverlappingDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyServices: CatalogServiceDto[] = [];

  const dummyService1 = {
    id: 'overlapping-service-1',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.wix-studio.offering-1.overlapping.name'),
      tagLine: t('dummy.wix-studio.offering-1.overlapping.tagLine'),
      images: [
        {
          width: 3425,
          height: 4153,
          relativeUri: '11062b_ee4c6f7007fb49b5ab00657a5be9471a~mv2.jpg',
          fileName: '11062b_ee4c6f7007fb49b5ab00657a5be9471a~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: t('dummy.wix-studio.currency'),
      price: 50,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService2 = {
    id: 'overlapping-service-2',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.wix-studio.offering-2.overlapping.name'),
      tagLine: t('dummy.wix-studio.offering-2.overlapping.tagLine'),
      images: [
        {
          width: 4480,
          height: 3769,
          relativeUri: 'e320d0_900b3fc1abbc4cb9a42913b88d416521~mv2.jpg',
          fileName: 'e320d0_900b3fc1abbc4cb9a42913b88d416521~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: t('dummy.wix-studio.currency'),
      price: 75,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService3 = {
    id: 'overlapping-service-3',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.wix-studio.offering-3.overlapping.name'),
      tagLine: t('dummy.wix-studio.offering-3.overlapping.tagLine'),
      images: [
        {
          width: 5760,
          height: 6720,
          relativeUri: '11062b_85d5afe9a7744a7382481773058866c8~mv2.jpg',
          fileName: '11062b_85d5afe9a7744a7382481773058866c8~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: t('dummy.wix-studio.currency'),
      price: 50,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };

  // @ts-expect-error
  dummyServices.push(dummyService1, dummyService2);
  return dummyServices;
};

export const createStripDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyServices: CatalogServiceDto[] = [];

  const dummyService1 = {
    id: 'strip-service-1',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.offering-1.strip.name.v1'),
      tagLine: t('dummy.offering-1.strip.tagLine.v1'),
      images: [
        {
          width: 5651,
          height: 4129,
          relativeUri: '11062b_54ca9853021049c8abe5ccd1bd63eb4c~mv2.jpg',
          fileName: '11062b_54ca9853021049c8abe5ccd1bd63eb4c~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 80,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService2 = {
    id: 'strip-service-2',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.offering-2.strip.name.v1'),
      tagLine: t('dummy.offering-2.strip.tagLine.v1'),
      images: [
        {
          width: 5651,
          height: 4277,
          relativeUri: '11062b_1b367614d3cb4bf587f43e2b1c10d5bb~mv2.jpg',
          fileName: '11062b_1b367614d3cb4bf587f43e2b1c10d5bb~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 60,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService3 = {
    id: 'strip-service-3',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.offering-3.strip.name.v1'),
      tagLine: t('dummy.offering-3.strip.tagLine.v1'),
      images: [
        {
          width: 4820,
          height: 3595,
          relativeUri: '11062b_6d591d82e9e34ede89903b94a4388b7c~mv2.jpg',
          fileName: '11062b_6d591d82e9e34ede89903b94a4388b7c~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 35,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };

  // @ts-expect-error
  dummyServices.push(dummyService1, dummyService2, dummyService3);
  return dummyServices;
};

export const createEditorXStripDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyServices: CatalogServiceDto[] = [];

  const dummyService1 = {
    id: 'strip-service-1',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.editor-x.offering-1.strip.name'),
      tagLine: t('dummy.editor-x.offering-1.strip.tagLine'),
      images: [
        {
          width: 5616,
          height: 3744,
          relativeUri: '11062b_7d236aed806f48b6ab70c24c537c5640~mv2.jpg',
          fileName: '11062b_7d236aed806f48b6ab70c24c537c5640~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 75,
    },
    schedulePolicy: {},
    scheduleHeader: {},

    dummy: true,
  };
  const dummyService2 = {
    id: 'strip-service-2',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.editor-x.offering-2.strip.name'),
      tagLine: t('dummy.editor-x.offering-2.strip.tagLine'),
      images: [
        {
          width: 5472,
          height: 3648,
          relativeUri: '11062b_a00383fc89cf48b28d60d47be508e082~mv2.jpg',
          fileName: '11062b_a00383fc89cf48b28d60d47be508e082~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 40,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService3 = {
    id: 'strip-service-3',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.editor-x.offering-3.strip.name'),
      tagLine: t('dummy.editor-x.offering-3.strip.tagLine'),
      images: [
        {
          width: 4261,
          height: 3196,
          relativeUri: '11062b_97f61437530d440f9c18def8124e67ec~mv2.jpg',
          fileName: '11062b_97f61437530d440f9c18def8124e67ec~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 130,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };

  // @ts-expect-error
  dummyServices.push(dummyService1, dummyService2, dummyService3);
  return dummyServices;
};

export const createWixStudioStripDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyServices: CatalogServiceDto[] = [];

  const dummyService1 = {
    id: 'strip-service-1',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.wix-studio.offering-1.strip.name'),
      tagLine: t('dummy.wix-studio.offering-1.strip.tagLine'),
      images: [
        {
          width: 5616,
          height: 3744,
          relativeUri: '11062b_7d236aed806f48b6ab70c24c537c5640~mv2.jpg',
          fileName: '11062b_7d236aed806f48b6ab70c24c537c5640~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: t('dummy.wix-studio.currency'),
      price: 75,
    },
    schedulePolicy: {},
    scheduleHeader: {},

    dummy: true,
  };
  const dummyService2 = {
    id: 'strip-service-2',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.wix-studio.offering-2.strip.name'),
      tagLine: t('dummy.wix-studio.offering-2.strip.tagLine'),
      images: [
        {
          width: 5472,
          height: 3648,
          relativeUri: '11062b_a00383fc89cf48b28d60d47be508e082~mv2.jpg',
          fileName: '11062b_a00383fc89cf48b28d60d47be508e082~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: t('dummy.wix-studio.currency'),
      price: 40,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService3 = {
    id: 'strip-service-3',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.wix-studio.offering-3.strip.name'),
      tagLine: t('dummy.wix-studio.offering-3.strip.tagLine'),
      images: [
        {
          width: 4261,
          height: 3196,
          relativeUri: '11062b_97f61437530d440f9c18def8124e67ec~mv2.jpg',
          fileName: '11062b_97f61437530d440f9c18def8124e67ec~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: t('dummy.wix-studio.currency'),
      price: 130,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };

  // @ts-expect-error
  dummyServices.push(dummyService1, dummyService2, dummyService3);
  return dummyServices;
};

export const createGridDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyServices: CatalogServiceDto[] = [];

  const dummyService1 = {
    id: 'grid-service-1',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.GROUP,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.offering-1.grid.name.v1'),
      tagLine: t('dummy.offering-1.grid.tagLine.v1'),
      images: [
        {
          width: 6359,
          height: 4042,
          relativeUri: '11062b_a25a342ff395431f9bcdda5e68648d3e~mv2.jpg',
          fileName: '11062b_a25a342ff395431f9bcdda5e68648d3e~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 50,
      isFree: false,
      isVariedPricing: false,
      priceText: 'price text',
      minCharge: 0,
      paymentType: 'OFFLINE',
    },
    schedulePolicy: {},
    scheduleHeader: {
      durationInMinutes: 150,
      days: ['mon', 'wed'],
    },
    dummy: true,
  };
  const dummyService2 = {
    id: 'grid-service-2',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.GROUP,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.offering-2.grid.name.v1'),
      tagLine: t('dummy.offering-2.grid.tagLine.v1'),
      images: [
        {
          width: 3467,
          height: 2311,
          relativeUri: '11062b_edc564b4d9d745e5943f4ebabedc007a~mv2.jpg',
          fileName: '11062b_edc564b4d9d745e5943f4ebabedc007a~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 35,
    },
    schedulePolicy: {},
    scheduleHeader: {
      durationInMinutes: 120,
      days: ['fri'],
    },
    dummy: true,
  };
  const dummyService3 = {
    id: 'grid-service-3',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.GROUP,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.offering-3.grid.name.v1'),
      tagLine: t('dummy.offering-3.grid.tagLine.v1'),
      images: [
        {
          width: 3467,
          height: 2311,
          relativeUri: '11062b_c2d2871f620b43bca4d28cfdaf02c686~mv2.jpg',
          fileName: '11062b_c2d2871f620b43bca4d28cfdaf02c686~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 40,
    },
    schedulePolicy: {},
    scheduleHeader: {
      durationInMinutes: 120,
      days: ['tue'],
    },
    dummy: true,
  };

  // @ts-expect-error
  dummyServices.push(dummyService1, dummyService2, dummyService3);
  return dummyServices;
};

export const createEditorXGridDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyServices: CatalogServiceDto[] = [];

  const dummyService1 = {
    id: 'grid-service-1',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.editor-x.offering-1.grid.name'),
      tagLine: t('dummy.editor-x.offering-1.grid.tagLine'),
      images: [
        {
          width: 3648,
          height: 3644,
          relativeUri: '11062b_97d1f052abe64d21b75fb9b208f18074~mv2.jpg',
          fileName: '11062b_97d1f052abe64d21b75fb9b208f18074~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 55,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService2 = {
    id: 'grid-service-2',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.editor-x.offering-2.grid.name'),
      tagLine: t('dummy.editor-x.offering-2.grid.tagLine'),
      images: [
        {
          width: 2400,
          height: 2000,
          relativeUri: '11062b_fe1121cd7bf644edbd8e7c125fe4be63~mv2.jpg',
          fileName: '11062b_fe1121cd7bf644edbd8e7c125fe4be63~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 45,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    baseUrl: 'https://kaki.com',
    path: '/service-id-0',
    dummy: true,
  };
  const dummyService3 = {
    id: 'grid-service-3',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.editor-x.offering-3.grid.name'),
      tagLine: t('dummy.editor-x.offering-3.grid.tagLine'),
      images: [
        {
          width: 6123,
          height: 8164,
          relativeUri: '11062b_422ec8ffcfeb4ed4b36cc3d5e295ed29~mv2.jpg',
          fileName: '11062b_422ec8ffcfeb4ed4b36cc3d5e295ed29~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 35,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };

  // @ts-expect-error
  dummyServices.push(dummyService1, dummyService2, dummyService3);
  return dummyServices;
};

export const createWixStudioGridDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyServices: CatalogServiceDto[] = [];

  const dummyService1 = {
    id: 'grid-service-1',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.wix-studio.offering-1.grid.name'),
      tagLine: t('dummy.wix-studio.offering-1.grid.tagLine'),
      images: [
        {
          width: 3648,
          height: 3644,
          relativeUri: 'e320d0_0f8062914df54e39befb5a8413061881~mv2.jpg',
          fileName: 'e320d0_0f8062914df54e39befb5a8413061881~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: t('dummy.wix-studio.currency'),
      price: 55,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService2 = {
    id: 'grid-service-2',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.wix-studio.offering-2.grid.name'),
      tagLine: t('dummy.wix-studio.offering-2.grid.tagLine'),
      images: [
        {
          width: 2400,
          height: 2000,
          relativeUri: 'e320d0_5602448a37da4362812c2315142ee165~mv2.jpg',
          fileName: 'e320d0_5602448a37da4362812c2315142ee165~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: t('dummy.wix-studio.currency'),
      price: 45,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    baseUrl: 'https://kaki.com',
    path: '/service-id-0',
    dummy: true,
  };
  const dummyService3 = {
    id: 'grid-service-3',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.wix-studio.offering-3.grid.name'),
      tagLine: t('dummy.wix-studio.offering-3.grid.tagLine'),
      images: [
        {
          width: 6123,
          height: 8164,
          relativeUri: 'e320d0_90249e43b32f444783c840bd712e6d15~mv2.jpg',
          fileName: 'e320d0_90249e43b32f444783c840bd712e6d15~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: t('dummy.wix-studio.currency'),
      price: 35,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };

  // @ts-expect-error
  dummyServices.push(dummyService1, dummyService2, dummyService3);
  return dummyServices;
};

export const createSingleServiceDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyService = {
    id: 'single-service-service',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.GROUP,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.single-service-offering.name.v1'),
      tagLine: t('dummy.offering-2.grid.tagLine.v1'),
      images: [
        {
          width: 5161,
          height: 3492,
          relativeUri: '11062b_8cfb007420a640ddb5089533123cbdd8~mv2.jpg',
          fileName: '11062b_8cfb007420a640ddb5089533123cbdd8~mv2.jpg',
        },
      ],
    },
    payment: {
      isFree: true,
      priceText: 'Free',
    },
    schedulePolicy: {},
    scheduleHeader: {
      durationInMinutes: 30,
      days: ['mon', 'wed'],
    },
    dummy: true,
  };
  // @ts-expect-error
  return [dummyService];
};

export const createEditorXSingleServiceDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyService = {
    id: 'single-service-service',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.editor-x.single-service-offering.name'),
      tagLine: t('dummy.editor-x.single-service-offering.tagLine'),
      images: [
        {
          width: 3631,
          height: 4843,
          relativeUri: '11062b_241cfd042c4e473483aed978f2e87431~mv2.jpg',
          fileName: '11062b_241cfd042c4e473483aed978f2e87431~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 20,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };

  // @ts-expect-error
  return [dummyService];
};

export const createWixStudioSingleServiceDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyService = {
    id: 'single-service-service',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: t('dummy.wix-studio.single-service-offering.name'),
      tagLine: t('dummy.wix-studio.single-service-offering.tagLine'),
      images: [
        {
          width: 3631,
          height: 4843,
          relativeUri: 'e320d0_891a95d6c2b9426e873f8576c12a0d1a~mv2.jpg',
          fileName: 'e320d0_891a95d6c2b9426e873f8576c12a0d1a~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: t('dummy.wix-studio.currency'),
      price: 20,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };

  // @ts-expect-error
  return [dummyService];
};

export const createBookingsMainPageDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyServices: CatalogServiceDto[] = [];
  const dummyService1 = {
    id: 'main-page-service-1',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      images: [
        {
          width: 4527,
          height: 3018,
          relativeUri: '11062b_91fa0735368f425da0bd82073a8c3695~mv2.jpg',
          fileName: '11062b_91fa0735368f425da0bd82073a8c3695~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 45,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };

  const dummyService2 = {
    id: 'main-page-service-2',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      images: [
        {
          width: 3666,
          height: 2435,
          relativeUri: '11062b_0f11289602d54bafa37c6c028bc2431e~mv2.jpg',
          fileName: '11062b_0f11289602d54bafa37c6c028bc2431e~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 45,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };

  const dummyService3 = {
    id: 'main-page-service-3',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      images: [
        {
          width: 2607,
          height: 1738,
          relativeUri: '11062b_3a7a451a259a41878898a4e53b6b6eac~mv2.jpg',
          fileName: '11062b_3a7a451a259a41878898a4e53b6b6eac~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 45,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };

  const dummyService4 = {
    id: 'main-page-service-4',
    categoryId: DUMMY_CATEGORY_2,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      images: [
        {
          width: 2144,
          height: 1432,
          relativeUri: '11062b_9d4f78f3e4ec44deafd2d86e5a6d5eb5~mv2.jpg',
          fileName: '11062b_9d4f78f3e4ec44deafd2d86e5a6d5eb5~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 45,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };

  const dummyService5 = {
    id: 'main-page-service-5',
    categoryId: DUMMY_CATEGORY_2,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      images: [
        {
          width: 1761,
          height: 1175,
          relativeUri: '11062b_0d0ac3c3b9f5480d8a8636fe70e7d5b2~mv2.jpg',
          fileName: '11062b_0d0ac3c3b9f5480d8a8636fe70e7d5b2~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 45,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };

  const dummyService6 = {
    id: 'main-page-service-6',
    categoryId: DUMMY_CATEGORY_2,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      images: [
        {
          width: 1984,
          height: 1324,
          relativeUri: '11062b_8feb05dc20724cd0ae12466e62b51872~mv2.jpg',
          fileName: '11062b_8feb05dc20724cd0ae12466e62b51872~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 45,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };

  const dummyService7 = {
    id: 'main-page-service-7',
    categoryId: DUMMY_CATEGORY_3,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      images: [
        {
          width: 1592,
          height: 1061,
          relativeUri: '11062b_7be34dd7781a419892b217eae1188612~mv2.jpg',
          fileName: '11062b_7be34dd7781a419892b217eae1188612~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 45,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };

  const dummyService8 = {
    id: 'main-page-service-8',
    categoryId: DUMMY_CATEGORY_3,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      images: [
        {
          width: 4865,
          height: 3247,
          relativeUri: '11062b_64186737b5f44cb683764b92c4bb7791~mv2.jpg',
          fileName: '11062b_64186737b5f44cb683764b92c4bb7791~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 45,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };

  const dummyService9 = {
    id: 'main-page-service-9',
    categoryId: DUMMY_CATEGORY_3,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      images: [
        {
          width: 2401,
          height: 1500,
          relativeUri: '11062b_c5819ae1c5684aaa898164ccde6e970c~mv2.jpg',
          fileName: '11062b_c5819ae1c5684aaa898164ccde6e970c~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 45,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };

  dummyServices.push(
    // @ts-expect-error
    dummyService1,
    dummyService2,
    dummyService3,
    dummyService4,
    dummyService5,
    dummyService6,
    dummyService7,
    dummyService8,
    dummyService9,
  );

  return dummyServices;
};

export const createEditorXBookingsMainPageDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyServices: CatalogServiceDto[] = [];

  const dummyService1 = {
    id: 'main-page-service',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      tagLine: '',
      images: [
        {
          width: 6939,
          height: 5411,
          relativeUri: '11062b_77255a5b3f0247d6b8a5dc7f02ff601f~mv2.jpg',
          fileName: '11062b_77255a5b3f0247d6b8a5dc7f02ff601f~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 45,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService2 = {
    id: 'main-page-service',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      description: 'This service is awesome',
      tagLine: '',
      images: [
        {
          width: 5630,
          height: 3135,
          relativeUri: '11062b_0f2218fd616445c7ab09ca78aacdb169~mv2.jpg',
          fileName: '11062b_0f2218fd616445c7ab09ca78aacdb169~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 60,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService3 = {
    id: 'main-page-service',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      description: 'This service is awesome',
      tagLine: '',
      images: [
        {
          width: 3987,
          height: 4899,
          relativeUri: '11062b_871ca2ea5a6349bfab4edf5e6f72045c~mv2.jpg',
          fileName: '11062b_871ca2ea5a6349bfab4edf5e6f72045c~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 50,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService4 = {
    id: 'main-page-service',
    categoryId: DUMMY_CATEGORY_2,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      description: 'This service is awesome',
      tagLine: '',
      images: [
        {
          width: 3648,
          height: 3644,
          relativeUri: '11062b_97d1f052abe64d21b75fb9b208f18074~mv2.jpg',
          fileName: '11062b_97d1f052abe64d21b75fb9b208f18074~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 45,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService5 = {
    id: 'main-page-service',
    categoryId: DUMMY_CATEGORY_2,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      tagLine: '',
      images: [
        {
          width: 2400,
          height: 2000,
          relativeUri: '11062b_fe1121cd7bf644edbd8e7c125fe4be63~mv2.jpg',
          fileName: '11062b_fe1121cd7bf644edbd8e7c125fe4be63~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 45,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService6 = {
    id: 'main-page-service',
    categoryId: DUMMY_CATEGORY_2,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      tagLine: '',
      images: [
        {
          width: 6123,
          height: 8164,
          relativeUri: '11062b_422ec8ffcfeb4ed4b36cc3d5e295ed29~mv2.jpg',
          fileName: '11062b_422ec8ffcfeb4ed4b36cc3d5e295ed29~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 45,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService7 = {
    id: 'main-page-service',
    categoryId: DUMMY_CATEGORY_3,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      tagLine: '',
      images: [
        {
          width: 5760,
          height: 6720,
          relativeUri: '11062b_85d5afe9a7744a7382481773058866c8~mv2.jpg',
          fileName: '11062b_85d5afe9a7744a7382481773058866c8~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 50,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService8 = {
    id: 'main-page-service',
    categoryId: DUMMY_CATEGORY_3,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      tagLine: '',
      images: [
        {
          width: 4480,
          height: 3769,
          relativeUri: '11062b_bf0adb87a3c34b77b3444b84abd04515~mv2.jpg',
          fileName: '11062b_bf0adb87a3c34b77b3444b84abd04515~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 75,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService9 = {
    id: 'main-page-service',
    categoryId: DUMMY_CATEGORY_3,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      tagLine: '',
      images: [
        {
          width: 3425,
          height: 4153,
          relativeUri: '11062b_ee4c6f7007fb49b5ab00657a5be9471a~mv2.jpg',
          fileName: '11062b_ee4c6f7007fb49b5ab00657a5be9471a~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 50,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };

  dummyServices.push(
    // @ts-expect-error
    dummyService1,
    dummyService2,
    dummyService3,
    dummyService4,
    dummyService5,
    dummyService6,
    dummyService7,
    dummyService8,
    dummyService9,
  );
  return dummyServices;
};

export const createWixStudioBookingsMainPageDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyServices: CatalogServiceDto[] = [];

  const dummyService1 = {
    id: 'main-page-service',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      tagLine: '',
      images: [
        {
          width: 6939,
          height: 5411,
          relativeUri: 'e320d0_0f8062914df54e39befb5a8413061881~mv2.jpg',
          fileName: 'e320d0_0f8062914df54e39befb5a8413061881~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 45,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService2 = {
    id: 'main-page-service',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      description: 'This service is awesome',
      tagLine: '',
      images: [
        {
          width: 5630,
          height: 3135,
          relativeUri: 'e320d0_46976d46519c4a5daf28ecb11df3f105~mv2.jpg',
          fileName: 'e320d0_46976d46519c4a5daf28ecb11df3f105~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 60,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService3 = {
    id: 'main-page-service',
    categoryId: DUMMY_CATEGORY_1,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      description: 'This service is awesome',
      tagLine: '',
      images: [
        {
          width: 3987,
          height: 4899,
          relativeUri: 'e320d0_90249e43b32f444783c840bd712e6d15~mv2.jpg',
          fileName: 'e320d0_90249e43b32f444783c840bd712e6d15~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 50,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService4 = {
    id: 'main-page-service',
    categoryId: DUMMY_CATEGORY_2,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      description: 'This service is awesome',
      tagLine: '',
      images: [
        {
          width: 3648,
          height: 3644,
          relativeUri: 'e320d0_c0bad1b383554a82b1d4dfdb500eaa3a~mv2.jpg',
          fileName: 'e320d0_c0bad1b383554a82b1d4dfdb500eaa3a~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 45,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService5 = {
    id: 'main-page-service',
    categoryId: DUMMY_CATEGORY_2,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      tagLine: '',
      images: [
        {
          width: 2400,
          height: 2000,
          relativeUri: 'e320d0_805d10b202464cbea48af64c4c317622~mv2.jpg',
          fileName: 'e320d0_805d10b202464cbea48af64c4c317622~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 45,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService6 = {
    id: 'main-page-service',
    categoryId: DUMMY_CATEGORY_2,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      tagLine: '',
      images: [
        {
          width: 6123,
          height: 8164,
          relativeUri: 'e320d0_7eadd2a6e3804a32966d615c1ea647ca~mv2.jpg',
          fileName: 'e320d0_7eadd2a6e3804a32966d615c1ea647ca~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 45,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService7 = {
    id: 'main-page-service',
    categoryId: DUMMY_CATEGORY_3,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      tagLine: '',
      images: [
        {
          width: 5760,
          height: 6720,
          relativeUri: 'e320d0_42accd22653b4fbab5426c1a58e53932~mv2.jpg',
          fileName: 'e320d0_42accd22653b4fbab5426c1a58e53932~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 50,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService8 = {
    id: 'main-page-service',
    categoryId: DUMMY_CATEGORY_3,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      tagLine: '',
      images: [
        {
          width: 4480,
          height: 3769,
          relativeUri: 'e320d0_10fc85ec70374cd4a52afd8a61b66974~mv2.jpg',
          fileName: 'e320d0_10fc85ec70374cd4a52afd8a61b66974~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 75,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };
  const dummyService9 = {
    id: 'main-page-service',
    categoryId: DUMMY_CATEGORY_3,
    order: 0,
    type: ServiceType.INDIVIDUAL,
    offeredAs: ['ONE_TIME'],
    pricingPlanInfo: {
      displayText: null,
      pricingPlans: [],
    },
    info: {
      name: 'Service Title',
      tagLine: '',
      images: [
        {
          width: 3425,
          height: 4153,
          relativeUri: '11062b_ee4c6f7007fb49b5ab00657a5be9471a~mv2.jpg',
          fileName: '11062b_ee4c6f7007fb49b5ab00657a5be9471a~mv2.jpg',
        },
      ],
    },
    payment: {
      currency: 'USD',
      price: 50,
    },
    schedulePolicy: {},
    scheduleHeader: {},
    dummy: true,
  };

  dummyServices.push(
    // @ts-expect-error
    dummyService1,
    dummyService2,
    dummyService3,
    dummyService4,
    dummyService5,
    dummyService6,
    dummyService7,
    dummyService8,
    dummyService9,
  );
  return dummyServices;
};
