
import { CatalogServiceDto } from '@wix/bookings-uou-types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  createServiceImageViewModel,
  ServiceImageViewModel,
} from './serviceImageViewModel/serviceImageViewModel';
import {
  BodyViewModel,
  createBodyViewModel,
} from './bodyViewModel/bodyViewModel';
import { createFilterOptions } from './filterOptions/filterOptions';
import { createHeaderViewModel } from './headerViewModel/headerViewModel';
import { getServices } from './services/services';
import {
  FilterOption,
  Resources,
  ServiceListLayoutOptions,
  ViewMode,
} from '../types/types';
import { BusinessInfo as LegacyBusinessInfo } from '../../legacy/types';
import { CoursesAvailability } from '../actions/courseAvailability/CoursesAvailability';
import settingsParams from '../components/BookOnline/settingsParams';
import {
  createDialogViewModel,
  DialogViewModel,
} from './dialogViewModel/dialogViewModel';

export type WidgetViewModel = {
  services: CatalogServiceDto[];
  businessInfo: LegacyBusinessInfo;
  filterOptions: FilterOption[];
  serviceImageViewModel: ServiceImageViewModel;
  headerViewModel?: any;
  bodyViewModel: BodyViewModel;
  seo?: {
    shouldListServicesFromOtherCategories: boolean;
    allServices: CatalogServiceDto[];
  };
  isSEO: boolean;
  serviceListLayout: ServiceListLayoutOptions;
  coursesAvailability?: CoursesAvailability;
  navigatingToService?: string;
  shouldWorkWithAppSettings: boolean;
  dialogViewModel: DialogViewModel;
};

export const createWidgetViewModel = async ({
  filteredResources,
  businessInfo,
  flowAPI,
  scale,
  viewMode,
  isAnywhereFlow = false,
  shouldWorkWithAppSettings,
}: {
  filteredResources: Resources;
  businessInfo: LegacyBusinessInfo;
  flowAPI: ControllerFlowAPI;
  scale: number;
  viewMode: ViewMode;
  isAnywhereFlow?: boolean;
  shouldWorkWithAppSettings: boolean;
}): Promise<WidgetViewModel> => {
  const {
    settings,
    environment: { isMobile },
  } = flowAPI;
  const allServices = filteredResources.offerings;

  const filterOptions = createFilterOptions({
    flowAPI,
    filteredResources,
  });

  const services = await getServices({
    filterOptions,
    filteredResources,
    settings,
    isAnywhereFlow,
  });

  const serviceListLayout = settings.get(settingsParams.serviceListLayout);

  const serviceImageViewModel = createServiceImageViewModel({
    settings,
    isMobile,
    serviceListLayout,
  });

  const bodyViewModel = await createBodyViewModel({
    flowAPI,
    scale,
    imageAspectRatio: serviceImageViewModel.aspectRatio,
    serviceListLayout,
    services,
  });

  const headerViewModel = createHeaderViewModel(settings, filterOptions);

  const shouldListServicesFromOtherCategories =
    viewMode === ViewMode.PAGE && services.length !== allServices.length;
  const seo = {
    shouldListServicesFromOtherCategories,
    allServices,
  };

  const dialogViewModel = createDialogViewModel();

  return {
    services,
    filterOptions,
    serviceListLayout,
    serviceImageViewModel,
    headerViewModel,
    bodyViewModel,
    businessInfo,
    seo,
    isSEO: flowAPI.environment.isSEO,
    shouldWorkWithAppSettings,
    dialogViewModel,
  };
};
